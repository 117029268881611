import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b0a67c1c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "courselearn-container" }
const _hoisted_2 = { class: "first-tab" }
const _hoisted_3 = { class: "second-tab" }
const _hoisted_4 = { class: "third-tab" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!
  const _component_course_classify = _resolveComponent("course-classify")!
  const _component_van_tab = _resolveComponent("van-tab")!
  const _component_class_learn_task = _resolveComponent("class-learn-task")!
  const _component_class_learn_history = _resolveComponent("class-learn-history")!
  const _component_van_tabs = _resolveComponent("van-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_nav_bar, {
      "left-arrow": "",
      title: _ctx.$route.meta.title,
      placeholder: "",
      onClickLeft: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(-1)))
    }, null, 8, ["title"]),
    _createVNode(_component_van_tabs, {
      active: _ctx.active,
      "onUpdate:active": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.active) = $event)),
      animated: "",
      swipeable: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_tab, { title: "课程分类" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_course_classify)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "学习任务" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_class_learn_task)
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_van_tab, { title: "历史学习" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_class_learn_history)
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["active"])
  ]))
}