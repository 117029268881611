import request from "@/utils/request";

// 课程分类列表
export function classifylist() {
  return request({
    url: "/train/mobile/class/learn/classify/list",
    method: "get",
  });
}

//课程列表
export function classpage(arg: any) {
  return request({
    url: "/train/mobile/class/learn/page",
    method: "get",
    params: arg,
  });
}

// 历史学习列表
export function classHistory(params: any) {
  return request({
    url: "/train/mobile/class/learn/history",
    method: "get",
    params: params,
  });
}

// 学习任务列表
export function classTask(params: any) {
  return request({
    url: "/train/mobile/class/learn/task",
    method: "get",
    params: params,
  });
}

// 课程详情内容(课程信息+课程视频列表)
export function classinfo(params: any) {
  return request({
    url: "/train/mobile/class/learn/detail/",
    method: "get",
    params: params,
  });
}

// 人脸拍照识别
export function facecheck(arg: any) {
  return request({
    url: "/train/mobile/class/learn/face/check",
    method: "post",
    data: arg,
  });
}

// 视频播放结束调用接口
export function videoFinish(data: any) {
  return request({
    url: "/train/mobile/class/learn/item/finish",
    method: "post",
    data: data,
  });
}
