
import * as api from "@/api/courselearn";
import { Options, Vue } from "vue-property-decorator";

@Options({})
export default class extends Vue {
  private activeKey = 0;
  private refreshing = false;
  private loading = false;
  private finished = false;
  private classlist: any[] = [];
  private classifyDetail: any[] = [];

  mounted() {
    this.getClassifyList();
  }

  getClassifyList() {
    api.classifylist().then((res: any) => {
      if (res.code === 0) {
        this.classlist = res.data;
      } else {
        this.$toast.fail(res.msg);
      }
    });
  }

  protected sidebarchange(val: any) {
    this.refreshing = true;
    this.activeKey = val;
    this.onRefresh();
  }

  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.classifyDetail = [];
        this.refreshing = false;
      }

      api
        .classpage({
          pageSize: 10,
          pageNum: parseInt(this.classifyDetail.length / 10 + "") + 1,
          projectId: this.$store.state.project.projectinfo.projectId,
          classifyId: this.classlist[this.activeKey].id,
        })
        .then((res: any) => {
          this.classifyDetail = [...this.classifyDetail, ...res.data.records];
          this.loading = false;

          if (this.classifyDetail.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 1000);
  }

  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }

  protected openClass(item: any) {
    if (item.status === 1) {
      this.$router.push({ path: "/app/courseplay", query: { id: item.id } });
    } else {
      this.$toast.fail("此课程已下架");
    }
  }
}
