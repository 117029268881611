
import { Options, Vue } from "vue-property-decorator";
import courseClassify from "./courseclassify.vue";
import classLearnHistory from "./classlearnhistory.vue";
import classLearnTask from "./classlearntask.vue";

@Options({
  components: {
    courseClassify,
    classLearnHistory,
    classLearnTask,
  },
})
export default class extends Vue {
  private active = 0;
}
