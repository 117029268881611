
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/courselearn";

@Options({})
export default class extends Vue {
  private classTaskList: any[] = [];
  private loading = false;
  private finished = false;
  private refreshing = false;

  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.classTaskList = [];
        this.refreshing = false;
      }

      api
        .classTask({
          pageSize: 5,
          pageNum: parseInt(this.classTaskList.length / 5 + "") + 1,
          projectId: this.$store.state.project.projectinfo.projectId,
        })
        .then((res: any) => {
          this.classTaskList = [...this.classTaskList, ...res.data.records];
          this.loading = false;

          if (this.classTaskList.length >= res.data.total) {
            this.finished = true;
          }
        });
    }, 1000);
  }

  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }

  protected openClass(item: any) {
    if (item.status === 1) {
      this.$router.push({ path: "/app/courseplay", query: { id: item.id } });
    } else {
      this.$toast.fail("此课程已下架");
    }
  }
}
